import React from 'react'
import styled from 'styled-components'
import Layout from '@components/Layout'
import Hero from '@components/Hero'
import YTEmbed from '@components/YTEmbed'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import theme, { makeGradient } from '../../config/theme';
import Color from 'color'

// TODO: refactor this
const _StyledYTE = ({ className, ...props }) =>
  <div className={className}>
    <YTEmbed {...props} title="Video" />
  </div>
_StyledYTE.propTypes = {
  ...YTEmbed.propTypes,
  className: PropTypes.string
}

const StyledYTE = styled(_StyledYTE)`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: calc(100% * 9/16);

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
`
StyledYTE.propTypes = _StyledYTE.propTypes

const Section = styled.section`
  padding: 5rem 3rem;
  display: flex;
  font-size: 1.1em;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:not(:last-child) {
    border-bottom: 1px rgba(0,0,0,0.1) solid;
  }

  & > * {
    text-align: center;
    margin: 0;
  }
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`

const Button = styled(Link)`
  color: ${theme.colors.light.color};
  background: ${makeGradient(theme.colors.primary, '135deg')};
  text-decoration: none;

  font-family: ${theme.typography.headerFonts.join(',')};
  font-size: 1.2rem;
  font-weight: bold;

  padding: 0.5em 0.75em;
  border-radius: 0.5em;

  box-shadow: 0 0.2em 1em ${Color(theme.colors.primary.color).alpha(.5).string()};

  transition: all 0.3s;
  transform: translateY(0);
  :hover, :focus {
    transform: translateY(-.2rem);
    box-shadow: 0 0.3em 1.2em ${Color(theme.colors.primary.color).alpha(.6).string()};
  }

  & > * {
    margin: 0;
    display: inline;
  }
`

const Index = () =>
  <Layout>
    <Hero big>
      <h1>Hi.</h1>
      <h2>I&apos;m Ethan</h2>
    </Hero>
    <main>
      <Section>
        <h1>I make things</h1>
        <Button to='/projects'>Projects</Button>
        <p>
          From electronics and hardware to embedded software and web technologies, I&apos;ve done it all.
        </p>
      </Section>
      <Section>
        <h1>I share things</h1>
        <Button to='/presentations'>Presentations</Button>
        <p>
          I present my work through talks and published papers.
        </p>
      </Section>
      <Section>
        <StyledYTE videoId="joc4eLZlPME" lazy />
      </Section>
    </main>
  </Layout>

export default Index
